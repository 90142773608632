.adapter-dropdown{
    width: 100%;
    flex-grow: 1;
}

.tronWalletConnectButton{
    background-color: rgb(255 92 0/var(--tw-bg-opacity,1)) !important;
    width: 100% !important;
    height: 40px !important;
    font-size: 16px !important;
    font-family: 'Archivo', sans-serif !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: none !important;
    border-radius: 0.25rem !important;
    color: white !important;
    text-transform: none !important;
    font-weight: 400 !important;
    cursor: pointer !important;
    padding: 8px !important;
    .button-icon{
        background-color: rgb(238, 238, 238) !important;
        position: relative !important;
        padding: 4px !important;
        border-radius: 50% !important;
        height: 24px !important;
        width: 24px !important;
        img{
            // position: absolute !important;
            top: 0 !important;
            left: 0 !important;
            height: 100% !important;
            width: 100% !important;
            object-fit: contain !important;
        }
    }
}
  
@media (max-width: 1028px) {
    // Styles for md
    .tronWalletConnectButton{
        height: 34px !important;
        font-size: 12px !important;
        padding: 4px !important;
        .button-icon{
            display: none !important;
            height: 16px !important;
            width: 16px !important;
        }
    }
    .adapter-dropdown-list{
        li{
            font-size: 8px !important;
        }
    }
}
  
//   @media (min-width: 1024px) and (max-width: 1280px) {
//     // Styles for lg
//   }
  
//   @media (min-width: 1280px) and (max-width: 1440px) {
//     // Styles for xl
//   }
  
//   @media (min-width: 1440px) {
//     // Styles for 2xl
//   }
  